import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueSession from 'vue-session'
import VueSimpleAlert from "vue-simple-alert";
import Axios from "axios";
import VueLoading from "vuejs-loading-plugin";
import VueToast from 'vue-toast-notification';

import VueMultiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

import {hostname} from '@/api/index';

Vue.prototype.$hostname = hostname;
Vue.prototype.$Axios = Axios;
import mixin from "./generalMixin";

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import
{
  CanvasRenderer
} from 'echarts/renderers'
import "echarts/lib/chart/line";
use([
  CanvasRenderer,

]);

import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);

Vue.component('v-chart', ECharts)
Vue.mixin({
  methods: {
    validName: mixin.methods.validName,
    validEmail: mixin.methods.validEmail,
    validNumber: mixin.methods.validNumber,
    config: mixin.methods.config,
    roleName: mixin.methods.roleName,
    checkAdmin: mixin.methods.checkAdmin,
    checkStore: mixin.methods.checkStore,
    checkLogin: mixin.methods.checkLogin,
    checkRootAdmin: mixin.methods.checkRootAdmin,
    cleanDate: mixin.methods.cleanDate,
    getPaymentText: mixin.methods.getPaymentText,
    getPaymentTextWakel: mixin.methods.getPaymentTextWakel,
    getPaymentTextZabwn: mixin.methods.getPaymentTextZabwn,
    getPaymentTextSairafa: mixin.methods.getPaymentTextSairafa,
    getPaymentTextClass: mixin.methods.getPaymentTextClass,
    formatDatee: mixin.methods.formatDatee,
    findEndDate: mixin.methods.findEndDate,
    getCurrencyText: mixin.methods.getCurrencyText,
    getTimeFromDate: mixin.methods.getTimeFromDate,
    numberToArabicText: mixin.methods.numberToArabicText,
    getTransactionCategoryText: mixin.methods.getTransactionCategoryText,
    getTransactionClassText: mixin.methods.getTransactionClassText,
    formatDateTimeWithMinutes:  mixin.methods.formatDateTimeWithMinutes,
    
  },
  data() {
    return {
      roles: mixin.data.roles,
    };
  },
});

import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
  position: 'top-right'
});

import loader from "./components/CustomeLoader"

Vue.use(VueSession)
Vue.use(VueSimpleAlert);
Vue.use(VueLoading, {
  dark: true, // default false
  customLoader: loader,
  background: "rgba(255,255,255,0.5)" // set custom background
});

Vue.component('vue-multiselect', VueMultiselect)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
