import { render, staticRenderFns } from "./MonthDaysListView.vue?vue&type=template&id=0b6fac5d&scoped=true"
import script from "./MonthDaysListView.vue?vue&type=script&lang=js"
export * from "./MonthDaysListView.vue?vue&type=script&lang=js"
import style0 from "./MonthDaysListView.vue?vue&type=style&index=0&id=0b6fac5d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6fac5d",
  null
  
)

export default component.exports