import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import LoginView from '../views/LoginView.vue'
import CustomersView from '../views/CustomersView.vue'

import CustomerProfileView from '../views/CustomerProfileView.vue'
import YearsReportView from '../views/Reports/YearsReportView.vue'
import DailyReportView from '../views/Reports/DailyReportView.vue'

import ClientDashboardView from '../views/ClientDashboard/ClientDashboardView.vue'
import ExpiresTodayReportView from '../views/Reports/ExpiresTodayReportView.vue'

import ChangePasswordView from '../views/Settings/ChangePassword.vue'

import SingleMonthReportView from '../views/Reports/SingleMonthReportView.vue'

import TasweqList from '@/views/Tasweq/TasweqListView.vue'
import TasweqProfile from '@/views/Tasweq/TasweqProfile.vue'


import ExpenseType from '@/views/ExpenseType/ExpenseTypeListView.vue'
import NewExpense from '@/views/ExpenseList/NewExpense.vue'
import YearMonthExpense from '@/views/ExpenseList/YearMonthListView.vue'
import MonthDaysExpenses from '@/views/ExpenseList/MonthDaysListView.vue'
import SpecificDayExpense from '@/views/ExpenseList/SingleDayExpenseList.vue'

import LogsList from '@/views/Logs/LogsList.vue'

import Sanadat from '@/views/Sanadat/SanadatList.vue'

import businessReport from '../views/Reports/BusinessReport.vue'



//import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,

  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/Customers/:type',
    name: 'customers',
    component: CustomersView
  },
  {
    path: '/Customer/Profile/:id/:type',
    name: 'customerProfile',
    component: CustomerProfileView
  },
  {
    path: '/Reports/Years',
    name: 'YearsReport',
    component: YearsReportView
  },
  {
    path: '/Reports/Daily',
    name: 'DailyReport',
    component: DailyReportView
  },
  {
    path: '/Reports/ExpiresToday',
    name: 'ExpiresTodayReport',
    component: ExpiresTodayReportView
  },

  {
    path: '/Client/Dashboard',
    name: 'ClientDashboard',
    component: ClientDashboardView,
  },
  {
    path: '/Settings/ChangePassword',
    name: 'ChangePassword',
    component: ChangePasswordView,
  },
  {
    path: '/Reports/SingleMonth/Month/:month/year/:year',
    name: 'SingleMonthReport',
    component: SingleMonthReportView,
  },
  {
    path: '/Tasweq/List',
    name: 'TasweqList',
    component: TasweqList,
  },
  {
    path: '/Tasweq/Profile/:id',
    name: 'TasweqProfile',
    component: TasweqProfile
  },
  {
    path: '/ExpenseType/List/:isExpense',
    name: 'ExpenseType',
    component: ExpenseType
  },

  {
    path: '/Expenses/Daily/Create/:isExpense',
    name: 'NewExpense',
    component: NewExpense
  },
  {
    path: '/Expenses/Monthly/List/:isExpense',
    name: 'YearMonthExpense',
    component: YearMonthExpense
  },
  {
    path: '/Expenses/Monthly/year/:year/month/:month/:isExpense',
    name: 'MonthDaysExpenses',
    component: MonthDaysExpenses
  },
  {
    path: '/Expenses/Daily/year/:year/month/:month/day/:day/:isExpense',
    name: 'SpecificDayExpense',
    component: SpecificDayExpense
  },
  {
    path: '/Logs/List',
    name: 'LogsList',
    component: LogsList
  },
  {
    path: '/Bonds/List',
    name: 'SanadatList',
    component: Sanadat
  },
  {
    path: '/Report/Business',
    name: 'BusinessReport',
    component: businessReport
  },

  { path: '/404', redirect: '/login' },
  { path: '*', redirect: '/404' },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) =>
// {
//   if (!store.state.loggedUser)
//   {
//     // You can use store variable here to access globalError or commit mutation 
//     next("/Login")
//   } else if (store.state.loggedUser)
//   {

//     next();
//   }
//   else
//   {
//     next()
//   }
// })

export default router
