<style scoped>
.table td,
th {
  padding: 10px;
  text-align: center;
}

.bg-specific {
  background-color: #1f3bb3;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 5px;
}

.reportBtns button {
  margin-right: 5px;
}

.paymentFilterDropDown {
  height: 1.9rem;
  appearance: auto;
  padding: 2px;
  margin-top: 3px;
}
</style>

<template>
  <div>
    <h3
      class="mt-2 mb-2"
      :class="isDarkMode ? 'dark-active-text-color' : 'light-active-text-color'"
    >
      {{
        $tc("graphText.dailyReportFromTo", 0, {
          dateFrom: dailyParams.from,
          dateTo: dailyParams.to,
        })
      }}
    </h3>
    <hr />
    <div class="card rounded-2">
      <div class="card-body">
        <div class="row justify-content-between">
          <!-- <div class="col col-lg-4 col-12">
            <div class="form-group">
              <label for="">{{ $t("date") }}</label>
              <date-picker
                class="w-100"
                format="DD-MM-YYYY"
                :lang="lang"
                v-model="time3"
                range
              ></date-picker>
            </div>
          </div> -->
          <div class="col col-lg-6 col-12">
            <div class="form-group">
              <label for="">{{ $t("buttonReports.todayTitle") }}</label>
              <div class="d-lg-flex reportBtns mt-1">
                <button
                  class="btn btn-sm rounded-1"
                  @click="selectFilter('today')"
                  :class="selectedFilter.today ? 'btn-success' : 'btn-outline-success'"
                >
                  {{ $t("buttonReports.today") }}
                </button>
                <button
                  class="btn btn-sm rounded-1"
                  @click="selectFilter('yesterday')"
                  :class="selectedFilter.yesterday ? 'btn-danger' : 'btn-outline-danger'"
                >
                  {{ $t("buttonReports.yesterday") }}
                </button>
                <button
                  class="btn btn-sm rounded-1"
                  @click="selectFilter('lastWeek')"
                  :class="selectedFilter.lastWeek ? 'btn-primary' : 'btn-outline-primary'"
                >
                  {{ $t("buttonReports.last7Days") }}
                </button>
                <button
                  class="btn btn-sm rounded-1"
                  @click="selectFilter('lastMonth')"
                  :class="
                    selectedFilter.lastMonth ? 'btn-warning' : 'btn-outline-warning'
                  "
                >
                  {{ $t("buttonReports.last30Days") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col col-lg-2 col-12">
            <div class="form-group">
              <label for="">{{ $t("addUserTransaction.classFilter") }}</label>
              <select
                class="form-control paymentFilterDropDown"
                v-model="selectedType"
                @change="applyTypeFilter()"
              >
                <option value="all">{{ $t("paymentTypeOptions.all") }}</option>
                <option value="agent">
                  {{ $t("filterType.agent") }}
                </option>
                <option value="tasweq">
                  {{ $t("filterType.tasweq") }}
                </option>
                <option value="zabwn">
                  {{ $t("filterType.zabwn") }}
                </option>
                <option value="sairafa">
                  {{ $t("filterType.sairafa") }}
                </option>
                <option value="expense">
                  {{ $t("filterType.expense") }}
                </option>
                <option value="import">
                  {{ $t("filterType.import") }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead class="bg-dark text-light">
              <tr>
                <th>#</th>
                <th>
                  {{ $t("customersTable.type") }}
                </th>
                <th>
                  {{ $t("customersTable.transaction") }}
                </th>

                <th>
                  {{ $t("customersTable.entity") }}
                </th>
                <th>
                  {{ $t("customersTable.note") }}
                </th>
                <th>
                  {{ $t("customersTable.dateTime") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transaction, index) in dailyArr.dailyReports"
                :key="transaction.id"
              >
                <td class="py-1">
                  {{ index + 1 }}
                </td>
                <td>
                  <span :class="getTransactionClassText(transaction.transactionType)">{{
                    getTransactionCategoryText(transaction.transactionType)
                  }}</span>
                </td>
                <td>
                  <div
                    v-if="
                      transaction.transactionType != 'expense' &&
                      transaction.transactionType != 'import'
                    "
                  >
                    <span v-if="transaction.transactionType == 'agent'">
                      {{ " تم " }}
                      <span
                        class="badge"
                        :class="getPaymentTextClass(transaction.paymentType)"
                        >{{ getPaymentTextWakel(transaction.paymentType) }}</span
                      >
                      {{ transaction.amount.toLocaleString() }}&nbsp;{{
                        getCurrencyText(transaction.currencyType)
                      }}
                    </span>
                    <span v-else-if="transaction.transactionType == 'tasweq'">
                      {{ " تم " }}
                      <span
                        class="badge"
                        :class="getPaymentTextClass(transaction.paymentType)"
                        >{{ getPaymentText(transaction.paymentType) }}</span
                      >
                      {{ transaction.amount.toLocaleString() }}&nbsp;{{
                        getCurrencyText(transaction.currencyType)
                      }}
                    </span>
                    <span v-else-if="transaction.transactionType == 'sairafa'">
                      {{ " تم " }}
                      <span
                        class="badge"
                        :class="getPaymentTextClass(transaction.paymentType)"
                        >{{ getPaymentTextSairafa(transaction.paymentType) }}</span
                      >
                      {{ transaction.amount.toLocaleString() }}&nbsp;{{
                        getCurrencyText(transaction.currencyType)
                      }}
                    </span>
                    <span v-else>
                      {{ " تم " }}
                      <span
                        class="badge"
                        :class="getPaymentTextClass(transaction.paymentType)"
                        >{{ getPaymentTextZabwn(transaction.paymentType) }}</span
                      >
                      {{ transaction.amount.toLocaleString() }}&nbsp;{{
                        getCurrencyText(transaction.currencyType)
                      }}
                    </span>
                  </div>
                  <div v-else>
                    {{ transaction.amount.toLocaleString() }}&nbsp;{{
                      getCurrencyText(transaction.currencyType)
                    }}
                    <span class="text-bold">{{
                      transaction.transactionType == "expense" ? " كمصروف " : ""
                    }}</span>
                  </div>
                </td>

                <td>
                  <div
                    v-if="
                      transaction.transactionType == 'expense' ||
                      transaction.transactionType == 'import'
                    "
                    class="text-bold"
                  >
                    {{ transaction.expenseType }}
                  </div>
                  <div v-else class="text-bold">
                    {{ transaction && transaction.user ? transaction.user.fullName : "" }}
                  </div>
                </td>
                <td>
                  {{ transaction.note }}
                </td>
                <td dir="ltr">
                  {{
                    transaction.transactionType != "expense" &&
                    transaction.transactionType != "import"
                      ? formatDateTimeWithMinutes(transaction.creationDate)
                      : cleanDate(transaction.creationDate)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <h4 v-else class="text-center text-secondary">{{ $t("noData") }}</h4> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ar";
export default {
  name: "DailyReport",
  //components: { DatePicker },
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
    //this.getDailyReport();
    this.selectFilter("today");
  },
  computed: {
    ...mapState(["isDarkMode"]),
  },
  data() {
    return {
      dailyArr: [],
      tempDailyArr: [],
      dailyParams: {
        from: this.formatDatee(new Date()),
        to: this.formatDatee(new Date()),
        dailyReports: null,
      },
      time3: [new Date(), new Date()],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },

      selectedFilter: {
        today: true,
        yesterday: false,
        lastWeek: false,
        lastMonth: false,
      },
      paymentTypeFilter: 0,

      selectedType: "all",
    };
  },
  methods: {
    filterPaymentType(type) {
      this.dailyArr.dailyReports = this.tempDailyArr;
      if (type == 0) {
        return;
      }

      this.dailyArr.dailyReports = this.dailyArr.dailyReports.filter(
        (x) => x.paymentType == type
      );
    },
    setNewDate(days) {
      return new Date(new Date().setDate(new Date().getDate() - days));
    },

    selectFilter(filter) {
      this.paymentTypeFilter = 0;
      this.time3 = [];
      for (var prop in this.selectedFilter) {
        this.selectedFilter[prop] = false;
      }
      this.selectedFilter[filter] = true;

      var startDate = new Date();
      var endDate = new Date();
      switch (filter) {
        case "today":
          startDate = new Date();
          endDate = new Date();
          break;
        case "yesterday":
          startDate = this.setNewDate(1);
          endDate = this.setNewDate(1);
          break;
        case "lastWeek":
          startDate = this.setNewDate(7);
          endDate = new Date();
          break;
        case "lastMonth":
          startDate = this.setNewDate(30);
          endDate = new Date();
          break;
        default:
          break;
      }

      this.dailyParams = {
        from: this.formatDatee(startDate),
        to: this.formatDatee(endDate),
      };
      this.time3.push(startDate);
      this.time3.push(endDate);
      this.getDailyReport();
    },
    applyTypeFilter() {
      this.getDailyReport();
    },
    getDailyReport() {
      this.$loading(true);
      this.$Axios
        .get(
          `${this.$hostname}/api/userTransactions/generateReportDetailsList?type=${this.selectedType}&from=${this.dailyParams.from}&to=${this.dailyParams.to}`,
          this.config()
        )
        .then((res) => {
          this.dailyArr = [];
          this.dailyParams.dailyReports = res.data;
          this.dailyArr = this.dailyParams;
          //   this.tempDailyArr = res.data;
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
  },
};
</script>
