<template>
  <div>
    <h1 class="h3 mb-3">{{ $t("report.businessReport") }}</h1>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body" v-if="businessResult">
            <LineChartGenerator
              style="font-family: 'Arabic'; height: 100px"
              :data="chartData"
              :chart-data="chartData"
              :chart-id="134"
              :height="100"
            />
          </div>
        </div>
      </div>
      <div class="col-12 my-3" v-if="businessResult">
        <div class="card rounded-1">
          <div class="card-body">
            <BarChart :chart-data="chartData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import BarChart from "@/components/Charts/BarChart.vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
import { get } from "@/api";
export default {
  name: "BusinessReport",
  components: {
    LineChartGenerator,
    BarChart,
  },
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  async mounted() {
    await this.loadBusinessReport();
  },
  data() {
    return {
      businessResult: null,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "دينار",
            backgroundColor: "#f87979",
            data: [],
          },
          {
            label: "دولار",
            backgroundColor: "#000000",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    async loadBusinessReport() {
      this.$loading(true);
      const URL = `/api/businessReportLogics`;
      this.businessResult = await get(URL);
      if (this.businessResult) {
        this.businessResult.forEach((element) => {
          this.chartData.labels.push(element.month);
          this.chartData.datasets[0].data.push(element.totalBusinessDinnar);
          this.chartData.datasets[1].data.push(element.totalBusinessDollar);
        });
      }
      this.$loading(false);
    },
  },
};
</script>
