<style scoped>
#invoice {
  padding: 0px;
}

.invoice {
  position: relative;
  background-color: #fff;
}

.invoice header {
  padding: 35px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #0d6efd;
}

.invoice .company-details {
  text-align: right;
}

.invoice .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
}

.name a {
  color: #0d6efd;
}

.invoice .contacts {
  margin-bottom: 20px;
}

.invoice .invoice-to {
  text-align: right;
}

.invoice .invoice-to .to {
  margin-top: 0;
  margin-bottom: 0;
  color: #0d6efd;
}

.invoice .invoice-details {
  text-align: left;
}

.invoice .invoice-details .invoice-id {
  margin-top: 0;
  color: #0d6efd;
}

.invoice main {
  padding-bottom: 50px;
}

.invoice main .thanks {
  margin-top: -100px;
  font-size: 2em;
  margin-bottom: 50px;
}

.invoice main .notices {
  padding-right: 6px;
  border-right: 6px solid #0d6efd;
  background: #e7f2ff;
  padding: 10px;
}

.invoice main .notices .notice {
  font-size: 1.2em;
}

.invoice table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.invoice table td,
.invoice table th {
  padding: 15px;
  background: #eee;
  border-bottom: 1px solid #fff;
}

.invoice table th {
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
}

.invoice table td h3 {
  margin: 0;
  font-weight: 400;
  color: #0d6efd;
  font-size: 25px;
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit {
  text-align: right;
  font-size: 1.2em;
}

.invoice table .no {
  color: #fff;
  font-size: 1.6em;
  background: #0d6efd;
}

.invoice table .unit {
  background: #ddd;
}

.invoice table .total {
  background: #0d6efd;
  color: #fff;
}

.invoice table tbody tr:last-child td {
  border: none;
}

.invoice table tfoot td {
  background: 0 0;
  border-bottom: none;
  white-space: nowrap;
  text-align: right;
  padding: 10px 20px;
  font-size: 1.2em;
  border-top: 1px solid #aaa;
}

.invoice table tfoot tr:first-child td {
  border-top: none;
}

.invoice table tfoot tr:last-child td {
  color: #0d6efd;
  font-size: 1.4em;
  border-top: 1px solid #0d6efd;
}

.invoice table tfoot tr td:first-child {
  border: none;
}

@media print {
  .invoice {
    font-size: 11px !important;
    overflow: hidden !important;
  }

  .invoice footer {
    position: absolute;
    bottom: 10px;
    page-break-after: always;
  }

  .invoice>div:last-child {
    page-break-before: always;
  }
}
</style>
<template>
  <div class="d-none">
    <!--Print card id="printMe"-->
    <div class="container" dir="rtl" id="printMe">
      <div id="invoice">

        <div class="invoice overflow-auto">
          <div style="min-width: 600px">
            <header>
              <div style="width: 100%; height: 100px" class="d-flex justify-content-between">
                <div class="company-det">
                  <h2 class="name">
                    <a target="_blank"> {{ $t("newtechTitle") }} </a>
                  </h2>
                  <div>{{ $t("newtech.location") }}</div>
                  <div>{{ $t("newtech.phoneNumber") }}</div>
                  <div>{{ $t("newtech.email") }}</div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center" v-if="userInfo">
                  <qrcode-vue :value="`https://account.newtech-isp.com/#/${userInfo.userIdentifer}`" :size="size" level="H" class="mt-4" />
                  <!-- <h6 class="mt-2 text-bold">{{ $t("scanQR") }}</h6> -->
                </div>
                <div class="logo">
                  <img src="/img/logo.ba0c940e.png" width="150" alt="" style="margin-top: -30px" />
                </div>
              </div>
            </header>
            <main>
              <div class="row contacts" v-if="userInfo">
                <div class="col invoice-to">
                  <div class="text-gray-light">{{ $t("invoice.invoiceTo") }}:</div>
                  <h4 class="to">{{ userInfo.fullName }}</h4>
                  <div class="address">{{ userInfo.phoneNumber }}</div>
                </div>
                <div class="col invoice-details">
                  <h3 class="invoice-id">{{ $t("invoice.title") }}</h3>
                  <div class="date">
                    {{ $t("invoice.date") }}: {{ cleanDate(new Date()) }}
                  </div>
                  <div class="date">
                    {{ $t("invoice.time") }}: {{ getTimeFromDate(new Date()) }}
                  </div>
                </div>
              </div>
              <table style="margin-top: -20px">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  <tr class="">
                    <td class="text-center amount-det" colspan="12">
                      <div class="mb-2">
                        <h3 v-if="transaction.transactionType == 'agent'">
                          {{
                      ' تم ' + getPaymentTextWakel(transaction.paymentType)
                    }}
                          {{ transaction.amount.toLocaleString() }}&nbsp;{{
                      getCurrencyText(transaction.currencyType)
                    }}
                        </h3>
                        <h3 v-else-if="transaction.transactionType == 'tasweq'">
                          {{
                      ' تم ' + getPaymentText(transaction.paymentType)
                    }}
                          {{ transaction.amount.toLocaleString() }}&nbsp;{{
                      getCurrencyText(transaction.currencyType)
                    }}
                        </h3>
                        <h3 v-else>
                          {{
                        ' تم ' + getPaymentTextZabwn(transaction.paymentType)
                      }}
                          {{ transaction.amount.toLocaleString() }}&nbsp;{{
                      getCurrencyText(transaction.currencyType)
                    }}
                        </h3>
                        <!-- <h3 v-if="transaction.paymentType == 1" class="text-success">
                          {{
                            transaction.transactionType == "agent"
                              ?  ' تم '  + $t("filters.qabz") 
                              : $t("successfullyPaid")
                          }}
                          {{ transaction.amount.toLocaleString() }}&nbsp;{{
                            getCurrencyText(transaction.currencyType)
                          }}
                        </h3>
                        <h3 v-else class="text-danger">
                          {{
                            transaction.transactionType == "agent"
                              ? ' تم '  + $t("filters.irsalRaset") 
                              : $t("withdraw")
                          }}

                          {{ transaction.amount.toLocaleString() }}&nbsp;
                          {{ getCurrencyText(transaction.currencyType) }}
                        </h3> -->
                      </div>

                      <span class="my-2">{{ numberToArabicText(transaction.amount) }}
                        {{ getCurrencyText(transaction.currencyType) }}</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1"></td>
                    <td colspan="1" style="font-weight: bold">
                      {{ $t("remainingIQD") }}
                    </td>
                    <td class="text-danger">
                      {{
                      (
                        userInfo.totalDebtDinnar - userInfo.totalPaidDinnar
                      ).toLocaleString()
                    }}
                      {{ $t("currency.main") }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8"></td>
                    <td colspan="1" style="font-weight: bold">
                      {{ $t("remainingDollar") }}
                    </td>
                    <td class="text-danger">
                      {{
                      (
                        userInfo.totalDebtDollar - userInfo.totalPaidDollar
                      ).toLocaleString()
                      }}
                      {{ $t("currency.secondary") }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8"></td>
                    <td colspan="1"></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
export default {
  name: "InvoicePrint",
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    QrcodeVue
  },
  methods: {
    
  },
  data(){
    return {
      size: 70
    }
  },
  
};
</script>
