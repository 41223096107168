<template>
  <div>
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="exampleModalSpecificExpenseType"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable" >
        <div class="modal-content ">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <h5 class="my-3 text-bold">{{ expenseType }}</h5>
            <div class="table-responsive" v-if="expenses && expenses.length > 0">
              <table class="table table-hover my-0 table-striped">
                <thead style="background-color: #222e3c">
                  <tr class="text-light text-center">
                    <th>#</th>
                    
                    <th>{{ $t("expenses.amount") }}</th>
                    <th>{{ $t("expenses.date") }}</th>
                    <th>{{ $t("expenses.note") }}</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(expense, index) in expenses"
                    :key="expense.id"
                  >
                    <td>{{ index + 1 }}</td>
                  
                    <td>
                      <div class="badge badge-opacity-danger me-3">
                        {{ expense.amount.toLocaleString() }}
                        <span>{{ getCurrencyText(expense.currencyType) }} </span>
                      </div>
                    </td>
                    <td>{{ cleanDate(expense.date) }}</td>
                    <td>{{ expense.note }}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ViewSpecificTypeExpense",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  props: ["expenses", "expenseType"],
  data() {
    return {};
  },
  methods: {},
};
</script>
