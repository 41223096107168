<style scoped>
.remove-top-margin {
  margin-top: -15px;
}
</style>

<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form class="forms-sample" @submit.prevent="postTransaction()">
              <div class="form-group" dir="rtl">
                <label class="typo__label text-bold" for="ajax">{{
                  $t("sanadt.name")
                }}</label>
                <div class="row justify-content-center align-items-center">
                  <div class="col" :class="isLoading ? 'col-11' : 'col-12'">
                    <vue-multiselect
                      class="mt-1"
                      v-model="selectedUsers"
                      id="ajax"
                      label="fullName"
                      track-by="id"
                      :placeholder="$t('searchNameHere')"
                      open-direction="bottom"
                      :options="users"
                      :multiple="true"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="1"
                      :limit-text="limitText"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                    >
                      <template slot="tag" slot-scope="{ option, remove }"
                        ><span class="custom__tag"
                          ><span>{{ option.fullName }}</span
                          ><span class="custom__remove" @click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      >
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="selectedUsers.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div>
                      </template>
                    </vue-multiselect>
                  </div>

                  <div class="col" :class="isLoading ? 'col-1' : 'd-none'">
                    <div
                      class="spinner-border"
                      role="status"
                      style="width: 30px; height: 30px"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="calcs" v-if="selectedUserDebts">
                <div class="row">
                  <div class="col col-lg-6 col-12">
                    <div class="form-group remove-top-margin">
                      <label for="exampleInput3d432 " class="text-bold">{{
                        $t("customersTable.amount")
                      }}</label>
                      <input
                        class="form-control"
                        id="exampleInput3d432"
                        disabled
                        :value="
                          (
                            selectedUserDebts.totalDebtDinnar -
                            selectedUserDebts.totalPaidDinnar
                          ).toLocaleString() +
                          ' ' +
                          $t('currency.main')
                        "
                      />
                    </div>
                  </div>
                  <div class="col col-lg-6 col-12">
                    <div class="form-group remove-top-margin">
                      <label for="exampleInput3df32 " class="text-bold">{{
                        $t("customersTable.amountDollar")
                      }}</label>
                      <input
                        class="form-control"
                        id="exampleInput3df32"
                        disabled
                        :value="
                          (
                            selectedUserDebts.totalDebtDollar -
                            selectedUserDebts.totalPaidDollar
                          ).toLocaleString() +
                          ' ' +
                          $t('currency.secondary')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group remove-top-margin">
                <label for="exampleInput3 " class="text-bold">{{
                  $t("addUserTransaction.amount")
                }}</label>
                <input
                  class="form-control"
                  id="exampleInput3"
                  type="number"
                  min="0"
                  v-model.number="customerTransactionBody.amount"
                />
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput4" class="text-bold">{{
                  $t("addUserTransaction.currencyType")
                }}</label>
                <select
                  v-model="customerTransactionBody.currencyType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="1">{{ $t("paymentTypeOptions.dinnar") }}</option>
                  <option value="2">{{ $t("paymentTypeOptions.dollar") }}</option>
                  <option value="3" v-if="shouldDisplayOption">
                    {{ $t("paymentTypeOptions.convertToDollar") }}
                  </option>
                  <option value="4" v-if="shouldDisplayOption">
                    {{ $t("paymentTypeOptions.convertToDinnar") }}
                  </option>
                </select>
              </div>

              <div
                class="form-group remove-top-margin"
                v-if="selectedUsers && selectedUsers[0] && selectedUsers[0].role == 4"
              >
                <label for="exampleInput4" class="text-bold">{{
                  $t("addUserTransaction.paymentType")
                }}</label>
                <select
                  v-model="customerTransactionBody.paymentType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="1">{{ $t("paymentTypeOptions.paid") }}</option>
                  <option value="2">{{ $t("paymentTypeOptions.debt") }}</option>
                  <option value="4">{{ $t("paymentTypeOptions.advance") }}</option>
                  <option value="5">{{ $t("paymentTypeOptions.buyProduct") }}</option>
                  <option value="6">{{ $t("paymentTypeOptions.hawalaSairafa") }}</option>
                </select>
              </div>
              <div
                class="form-group remove-top-margin"
                v-else-if="
                  selectedUsers && selectedUsers[0] && selectedUsers[0].role == 3
                "
              >
                <label for="exampleInput4" class="text-bold">{{
                  $t("addUserTransaction.paymentType")
                }}</label>
                <select
                  v-model="customerTransactionBody.paymentType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="2">{{ $t("filters.irsalRaset") }}</option>
                  <option value="1">{{ $t("filters.qabz") }}</option>
                  <option value="3">{{ $t("filters.return") }}</option>
                </select>
              </div>
              <div
                class="form-group remove-top-margin"
                v-else-if="
                  selectedUsers && selectedUsers[0] && selectedUsers[0].role == 6
                "
              >
                <label for="exampleInput4" class="text-bold">{{
                  $t("addUserTransaction.paymentType")
                }}</label>
                <select
                  v-model="customerTransactionBody.paymentType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="4">{{ $t("filters.dafhawala") }}</option>
                  <option value="1">{{ $t("filters.doHawala") }}</option>
                  <option value="3">{{ $t("filters.qabz") }}</option>
                </select>
              </div>
              <div class="form-group remove-top-margin" v-else>
                <label for="exampleInput4" class="text-bold">{{
                  $t("addUserTransaction.paymentType")
                }}</label>
                <select
                  v-model="customerTransactionBody.paymentType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="2">
                    {{ $t("filters.sarf") }}
                  </option>
                  <option value="1">{{ $t("filters.qabz") }}</option>
                  <option value="3">{{ $t("filters.sahbbb") }}</option>
                </select>
              </div>
              <div
                class="exchangeRates"
                v-if="
                  customerTransactionBody.currencyType == 3 ||
                  customerTransactionBody.currencyType == 4
                "
              >
                <div class="row">
                  <div class="col-6 form-group remove-top-margin">
                    <label>{{ $t("addUserTransaction.exchangeRate") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      :value="dollarPrice / 100"
                    />
                  </div>
                  <div class="col-6 form-group remove-top-margin">
                    <label>{{ $t("addUserTransaction.convertedAmount") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      :value="getConvertedAmount"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput6" class="text-bold">{{
                  $t("addUserTransaction.date")
                }}</label>
                <input
                  id="exampleInput6"
                  class="form-control"
                  type="date"
                  v-model="customerTransactionBody.date"
                />
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput7" class="text-bold">{{
                  $t("addUserTransaction.note")
                }}</label>
                <input
                  id="exampleInput7"
                  class="form-control"
                  type="text"
                  v-model="customerTransactionBody.note"
                />
              </div>
              <button type="submit" class="btn btn-primary me-2">
                <span>
                  {{
                    customerTransactionBody.id ? $t("dropdownOptions.edit") : $t("submit")
                  }}
                </span>
              </button>
              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-light modalCloseBtn"
              >
                {{ $t("close") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <InvoicePrint
      v-if="selectedPrintUser && selectedPrintTransaction"
      :transaction="selectedPrintTransaction"
      :userInfo="selectedPrintUser"
    />
    <h1
      class="h3 mb-3"
      :class="
        $store.state.isDarkMode ? 'dark-active-text-color' : 'light-active-text-color'
      "
    >
      {{ $t("sanadat") }}
    </h1>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
            <!-- Button trigger modal -->
            <button
              @click="resetFields"
              type="button"
              class="btn btn-primary btn-sm rounded-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              {{ $t("addNew") }}
            </button>
            <div class="table-responsive my-2">
              <table
                class="table table-sm table-striped table-hover text-center"
                v-if="transactions && transactions.items.length > 0"
              >
                <thead class="bg-dark text-light">
                  <tr>
                    <th>#</th>
                    <th>
                      {{ $t("addUserTransaction.to") }}
                    </th>
                    <th>
                      {{ $t("addUserTransaction.amount") }}
                    </th>
                    <th>
                      {{ $t("addUserTransaction.paymentType") }}
                    </th>

                    <th>
                      {{ $t("addUserTransaction.date") }}
                    </th>
                    <th>
                      {{ $t("addUserTransaction.note") }}
                    </th>
                    <th>
                      {{ $t("dropdownOptions.edit") }}
                    </th>
                    <th>
                      {{ $t("dropdownOptions.print") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in transactions.items"
                    :key="transaction.id"
                  >
                    <td class="py-1">
                      {{ ++index }}
                    </td>
                    <td class="text-bold">{{ transaction.user.fullName }}</td>
                    <td>
                      {{ transaction.amount.toLocaleString("en-US") }}
                      <span>{{ getCurrencyText(transaction.currencyType) }} </span>
                    </td>
                    <td>
                      <div
                        :class="getPaymentTextClass(transaction.paymentType)"
                        class="badge me-3"
                      >
                        {{
                          transaction.transactionType == "agent"
                            ? getPaymentTextWakel(transaction.paymentType)
                            : transaction.transactionType == "zabwn"
                            ? getPaymentTextZabwn(transaction.paymentType)
                            : transaction.transactionType == "sairafa"
                            ? getPaymentTextSairafa(transaction.paymentType)
                            : getPaymentText(transaction.paymentType)
                        }}
                      </div>
                    </td>
                    <td>
                      {{ cleanDate(transaction.date) }}
                    </td>

                    <td>
                      {{ transaction.note }}
                    </td>
                    <td>
                      <a role="button" @click="assignTransactionBody(transaction)">
                        <i class="mdi mdi-pencil big-icon"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        role="button"
                        @click="printInvoice(transaction.userDTO, transaction)"
                        v-print="'#printMe'"
                      >
                        <i class="icon-printer big-icon"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 v-else class="text-center text-secondary">{{ $t("noTransaction") }}</h4>
            </div>
            <Pagination
              dir="ltr"
              :pageIndex="pageIndex"
              :totalPages="totalPages"
              :onPageChange="handlePageChange"
              :onSizehange="handlePageSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "@/api";
import $ from "jquery";
import Pagination from "@/components/PaginationComponent.vue";
import InvoicePrint from "@/components/Prints/InvoicePrint.vue";
export default {
  name: "SanadatView",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  components: {
    Pagination,
    InvoicePrint,
  },
  computed: {
    totalItems() {
      return this.transactions ? this.transactions.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.transactions.items.slice(startIndex, startIndex + this.pageSize);
    },
    shouldDisplayOption() {
      return (
        this.selectedUsers.length > 0 &&
        (this.selectedUsers[0].role === 3 || this.selectedUsers[0].role === 5)
      );
    },
    getConvertedAmount() {
      if (this.customerTransactionBody.currencyType == 3) {
        return this.customerTransactionBody.amount / (this.dollarPrice / 100);
      } else if (this.customerTransactionBody.currencyType == 4) {
        return this.customerTransactionBody.amount * (this.dollarPrice / 100);
      }
      return 0;
    },
  },
  watch: {
    async "selectedUsers"(newVal) {
      if(newVal && newVal[0]){
        await this.getSpecificUserDebt(newVal[0].id)
      }
    },
  },
  data() {
    return {
      selectedUserDebts: null,
      dollarPrice: 0,
      value: null,
      options: ["list", "of", "options"],

      user: null,
      selectedUsers: [],
      users: [],
      isLoading: false,

      selectedPrintUser: null,
      selectedPrintTransaction: null,

      pageSize: 10,
      pageIndex: 1,
      transactions: null,

      customerTransactionBody: {
        id: 0,
        userId: this.selectedUsers ? this.selectedUsers[0].id : 0,
        amount: 0,
        convertedAmount: 0,
        transactionType:
          this.selectedUsers && this.selectedUsers[0].role == 4 ? "tasweq" : "agent",
        currencyType: 1,
        paymentType: 0,
        note: null,
        date: new Date(),
      },

      buttonReady: false,
    };
  },
  methods: {
    async getDollarPrice() {
      const URL = "/Api/Settings";
      this.dollarPrice = await get(URL);
    },

    printInvoice(user, transaction) {
      this.selectedPrintUser = user;
      this.selectedPrintTransaction = transaction;
    },
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.getTransactions();
    },
    async handlePageSize(newSize) {
      this.pageSize = newSize;
      await this.getTransactions();
    },
    resetFields() {
      this.selectedUserDebts = null;
      this.users = [];
      this.selectedUsers = [];
      this.customerTransactionBody = {
        id: 0,
        userId: 0,
        amount: 0,
        convertedAmount: 0,
        transactionType: null,
        currencyType: 1,
        paymentType: 0,
        note: null,
        date: new Date(),
      };
    },
    async getUserListSearch(query) {
      this.isLoading = true;
      const URL = `/api/users?search=${query}`;
      this.users = await get(URL);
      this.isLoading = false;
    },
    async getSpecificUserDebt(userId) {
      this.$loading(true);
      const URL = `/api/users/getSpecificUserDebts?userId=${userId}`;
      this.selectedUserDebts = await get(URL);
      this.$loading(false);
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    async asyncFind(query) {
      await this.getUserListSearch(query);
    },
    clearAll() {
      this.selectedUsers = [];
    },
    async getTransactions() {
      this.$loading(true);
      const URL = `/api/UserTransactions/getLatestTransactionsForSanads?page=${this.pageIndex}&size=${this.pageSize}`;
      this.transactions = await get(URL);
      this.$loading(false);
    },
    async getTheUser(userId) {
      this.selectedUsers = [];
      this.$loading(true);
      const URL = `api/users/specificUser?userId=${userId}`;
      this.user = await get(URL);
      this.selectedUsers.push(this.user);
      this.$loading(false);
    },

    async assignTransactionBody(transaction) {
      await this.getTheUser(transaction.userId);
      this.customerTransactionBody = {
        id: transaction.id,
        userId: parseInt(transaction.userId),
        amount: transaction.amount,
        convertedAmount: transaction.convertedAmount,
        transactionType: transaction.transactionType,
        currencyType: transaction.currencyType,
        paymentType: transaction.paymentType,
        note: transaction.note,
        date: this.formatDate(transaction.date),
      };

      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"));
      if (this.selectedUsers.length > 0) {
        myModal.show();
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async postTransaction() {
      if (this.selectedUsers.length == 0) return;
      this.$loading(true);
      this.isLoading = true;

      this.customerTransactionBody.userId = this.selectedUsers[0].id;

      this.customerTransactionBody.transactionType =
        this.selectedUsers[0].role == 4
          ? "tasweq"
          : this.selectedUsers[0].role == 3
          ? "agent"
          : this.selectedUsers[0].role == 5
          ? "zabwn"
          : "sairafa";

      this.customerTransactionBody.paymentType = parseInt(
        this.customerTransactionBody.paymentType
      );
      this.customerTransactionBody.currencyType = parseInt(
        this.customerTransactionBody.currencyType
      );
      this.customerTransactionBody.convertedAmount = this.getConvertedAmount;
      const URL = `/api/userTransactions`;
      await post(URL, this.customerTransactionBody);
      this.resetFields();
      this.isLoading = false
      $(".modalCloseBtn").trigger("click");

      await this.getTransactions();
      this.$loading(false);
    },
  },
  async mounted() {
    await this.getTransactions();
    await this.getDollarPrice();
  },
};
</script>
